$boxSize: 220px;

.category {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: normal;
  margin: 10px 10px;

  &:first-child {
    margin-left: 60px;
  }

  &:last-child {
    margin-right: 60px;
  }

  &Box {
    position: relative;
    height: $boxSize * 2;
    width: $boxSize;

    box-shadow: 0px 3px 6px 0 rgba( 31, 38, 135, 0.47 );
    border-radius: 10px;
    overflow: hidden;

    &Image {
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &Name {
      position: absolute;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60px;
      width: 100%;
      background-color: rgba(0,0,0, 0.7);
      border-radius: 10px;

      font-weight: bold;
      font-size: 20px;
    }
  }
}
