$bubbleSize: 40px;
$padding: 30px;

.footer {
  position: fixed;
  display: flex;
  bottom: 0;
  left: 0;
  right: 0;
  padding: $padding;
  height: 47.5px;
  backdrop-filter: blur(5px);

  .bubble {
    height: $bubbleSize;
    width: $bubbleSize;
    border-radius: 50%;
    background-color: red;
    padding: 7.5px;
    z-index: 2;
  }

  .left {
    position: absolute;
    left: $padding;
  }

  .right {
    position: absolute;
    right: $padding;
    transform: rotate(0deg);
    transition: all 300ms ease-in-out;
  }

  .active {
    transform: rotate(70deg);
  }

  .iconContainer {
    z-index: 1;
    display: flex;
    flex-direction: row-reverse;
    opacity: 0;
    align-items: center;
    width: 100%;
    margin-top: 10px;
    justify-content: flex-start;
    margin-right: 0;
    transition: all 300ms ease-in-out;
  }

  .iconContainerActive {
    opacity: 1;
    margin-right: $padding*2;
  }

  .icon {
    height: $bubbleSize - 5px;
    width: $bubbleSize - 5px;
    margin-right: 12.5px;
  }
}
