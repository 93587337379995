@import 'src/styles/variables/colors';

.productPage {
  width: 100vw;
  height: 100vh;
  overflow: auto;

  .product:last-child {
    margin-bottom: 100px;
  }
}

.description {
  margin: 20px 0;
  font-size: 18px;
  color: rgba(255,255,255,0.75);
}
