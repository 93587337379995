.adminCategoriesPage {
  max-width: 800px;
  height: 99vh;
  margin: 0 auto;
  overflow: hidden;

  form {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 120px;

    div {
      display: flex;
      flex-direction: column;

      @media only screen and (min-width: 768px) {
        flex-direction: row;

        input {
          margin-left: 10px;
        }
      }
    }
  }
}

.adminCategories {
  text-align: start;
  height: calc(100vh - 120px - 40px - 100px);
  overflow-y: auto;
  margin: 50px 0 40px 0;

  ul {
    padding: 0;

    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      list-style: none;
      font-size: 18px;
      padding: 10px 20px 10px 10px;

      &:nth-child(odd) {
        background-color: lighten(#282c34, 5);
      }

      .dragIcon {
        margin-right: 5px;
        vertical-align: middle;
      }

      .actions {
        span {
          cursor: pointer;
          &:not(:first-child) {
            margin: 0 0 0 10px;
          }
        }
      }
    }
  }
}

input {
  margin: 0 0 0.5rem 0;
}

.loading {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 9999;
  top: 0;
  left: 0;
  background: rgba(40, 44, 52, 0.74);;
  display: flex;
  justify-content: center;
  align-items: center;

  div.loading-spinner {
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
    border: 0.5rem solid rgba(0, 0, 0, 0.2);
    border-bottom: 0.5rem solid #00B0D6;
    animation: spinner 1s infinite ease-in-out;
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}