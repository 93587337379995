@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;200;400;900&display=swap);
body{margin:0;font-family:"Roboto",serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;background-color:#282c34;color:#fffaf0}::-webkit-input-placeholder{font-family:"Roboto",sans-serif}:-ms-input-placeholder{font-family:"Roboto",sans-serif}::placeholder{font-family:"Roboto",sans-serif}.error{color:red;font-size:.75rem}.success{color:#30cabb;font-size:.75rem}.imageContainer{content:"";position:absolute;top:0;left:0;width:100vw;height:100%;z-index:-1}.imageContainer img{width:100%;height:100%;-webkit-filter:brightness(0.4);filter:brightness(0.4);object-fit:cover;z-index:1}
.App {
  text-align: center;
}

.categoriesPage .logo{margin:25px auto 20px auto;width:80%;height:auto}.categoriesPage .categories{margin:0;display:flex;overflow-x:auto;scrollbar-width:none;-ms-overflow-style:none}.categoriesPage .categories::-webkit-scrollbar{display:none}
.category{display:flex;flex-direction:column;justify-content:center;align-items:center;font-weight:normal;margin:10px 10px}.category:first-child{margin-left:60px}.category:last-child{margin-right:60px}.categoryBox{position:relative;height:440px;width:220px;box-shadow:0px 3px 6px 0 rgba(31,38,135,.47);border-radius:10px;overflow:hidden}.categoryBoxImage{width:100%;height:100%}.categoryBoxImage img{width:100%;height:100%;object-fit:cover}.categoryBoxName{position:absolute;bottom:0;display:flex;justify-content:center;align-items:center;height:60px;width:100%;background-color:rgba(0,0,0,.7);border-radius:10px;font-weight:bold;font-size:20px}
.footer{position:fixed;display:flex;bottom:0;left:0;right:0;padding:30px;height:47.5px;-webkit-backdrop-filter:blur(5px);backdrop-filter:blur(5px)}.footer .bubble{height:40px;width:40px;border-radius:50%;background-color:red;padding:7.5px;z-index:2}.footer .left{position:absolute;left:30px}.footer .right{position:absolute;right:30px;transform:rotate(0deg);transition:all 300ms ease-in-out}.footer .active{transform:rotate(70deg)}.footer .iconContainer{z-index:1;display:flex;flex-direction:row-reverse;opacity:0;align-items:center;width:100%;margin-top:10px;justify-content:flex-start;margin-right:0;transition:all 300ms ease-in-out}.footer .iconContainerActive{opacity:1;margin-right:60px}.footer .icon{height:35px;width:35px;margin-right:12.5px}
.productPage{width:100vw;height:100vh;overflow:auto}.productPage .product:last-child{margin-bottom:100px}.description{margin:20px 0;font-size:18px;color:rgba(255,255,255,.75)}
.product{display:flex;justify-content:space-between;padding:10px 20px;align-items:flex-start}.product:nth-child(even){background-color:rgba(51,56,66,.7)}.productItem{display:flex;flex-direction:column}.productItem span{text-align:start}.productItem span:first-child{font-size:20px}.productItem span:nth-child(2){font-size:16px;font-weight:lighter}.productPrice{font-weight:bold;font-size:18px}
.adminCategoriesPage{max-width:800px;height:99vh;margin:0 auto;overflow:hidden}.adminCategoriesPage form{display:flex;align-items:center;flex-direction:column;height:120px}.adminCategoriesPage form div{display:flex;flex-direction:column}@media only screen and (min-width: 768px){.adminCategoriesPage form div{flex-direction:row}.adminCategoriesPage form div input{margin-left:10px}}.adminCategories{text-align:start;height:calc(100vh - 120px - 40px - 100px);overflow-y:auto;margin:50px 0 40px 0}.adminCategories ul{padding:0}.adminCategories ul li{display:flex;justify-content:space-between;align-items:center;list-style:none;font-size:18px;padding:10px 20px 10px 10px}.adminCategories ul li:nth-child(odd){background-color:#333842}.adminCategories ul li .dragIcon{margin-right:5px;vertical-align:middle}.adminCategories ul li .actions span{cursor:pointer}.adminCategories ul li .actions span:not(:first-child){margin:0 0 0 10px}input{margin:0 0 .5rem 0}.loading{position:absolute;width:100%;height:100%;z-index:9999;top:0;left:0;background:rgba(40,44,52,.74);display:flex;justify-content:center;align-items:center}.loading div.loading-spinner{width:10rem;height:10rem;border-radius:50%;border:.5rem solid rgba(0,0,0,.2);border-bottom:.5rem solid #00b0d6;animation:spinner 1s infinite ease-in-out}@keyframes spinner{0%{transform:rotate(0deg)}100%{transform:rotate(360deg)}}
.inputField{height:2rem;width:15rem;border-radius:.5rem;border:1px solid #cfd3d6;padding:0 .5rem 0 .5rem}.inputField::-webkit-input-placeholder{color:#cfd3d6}.inputField:-ms-input-placeholder{color:#cfd3d6}.inputField::placeholder{color:#cfd3d6}
.button{margin:.25em 0 0 0;border-radius:.5rem;border:none;height:2rem;width:15rem;background-color:#374958;color:#fafafa;font-size:1.2rem;font-weight:400}.button[type=reset]{background-color:#fafafa;color:#282c34}.button[type=button]{background-color:transparent;border:1px solid #374958}
.deleteBox{width:280px;height:180px;background-color:#282c34;font-size:20px;padding:20px;box-shadow:1px 1px 8px 0px #cfd3d6;border-radius:6px}.deleteContainer{position:absolute;top:0;left:0;height:100vh;width:100vw;background-color:rgba(40,44,52,.74);z-index:1000;display:flex;justify-content:center;align-items:center}
.editBox{width:280px;height:380px;background-color:#282c34;font-size:20px;padding:20px;box-shadow:1px 1px 8px 0px #cfd3d6;border-radius:6px}.editBox form{margin:40px 0 0 0}.editContainer{position:absolute;top:0;left:0;height:100vh;width:100vw;background-color:rgba(40,44,52,.74);z-index:1000;display:flex;justify-content:center;align-items:center}
.adminProductsPage{max-width:800px;height:99vh;margin:0 auto;overflow:hidden}.adminProductsPage form{display:flex;align-items:center;flex-direction:column;height:210px}@media only screen and (min-width: 768px){.adminProductsPage form{height:130px}}.adminProductsPage form div{display:flex;flex-direction:column}@media only screen and (min-width: 768px){.adminProductsPage form div{flex-direction:row}.adminProductsPage form div input{margin-left:10px}}.adminProducts{text-align:start;height:calc(100vh - 210px - 40px - 100px);overflow-y:auto;margin:50px 0 40px 0}@media only screen and (min-width: 768px){.adminProducts{height:calc(100vh - 130px - 40px - 100px)}}.adminProducts ul{padding:0}.adminProducts ul li{display:flex;justify-content:space-between;align-items:center;list-style:none;font-size:18px;padding:10px 20px 10px 10px}.adminProducts ul li:nth-child(odd){background-color:#333842}.adminProducts ul li .dragIcon{margin-right:5px;vertical-align:middle}.adminProducts ul li .actions span{cursor:pointer}.adminProducts ul li .actions span:not(:first-child){margin:0 0 0 10px}input{margin:0 0 .5rem 0}.loading{position:absolute;width:100%;height:100%;z-index:9999;top:0;left:0;background:rgba(40,44,52,.74);display:flex;justify-content:center;align-items:center}.loading div.loading-spinner{width:10rem;height:10rem;border-radius:50%;border:.5rem solid rgba(0,0,0,.2);border-bottom:.5rem solid #00b0d6;animation:spinner 1s infinite ease-in-out}@keyframes spinner{0%{transform:rotate(0deg)}100%{transform:rotate(360deg)}}
.selectComponent{display:flex;justify-content:center;position:relative}.selectComponent .categoriesList{display:none;position:absolute;top:2.2rem;width:15rem;border-radius:.5rem;background-color:#fafafa;color:#282c34;max-height:7rem;overflow-y:auto}.selectComponent .categoriesList:hover{display:block}.selectComponent .categoriesList ul{padding:0;margin:0}.selectComponent .categoriesList ul li{list-style-type:none;padding:.5rem .75rem;font-size:.75rem;border-bottom:1px #374958 solid}.selectComponent .inputField:focus+.categoriesList{display:block}
.editBox{width:280px;height:380px;background-color:#282c34;font-size:20px;padding:20px;box-shadow:1px 1px 8px 0px #cfd3d6;border-radius:6px}.editBox form{margin:40px 0 0 0}.editContainer{position:absolute;top:0;left:0;height:100vh;width:100vw;background-color:rgba(40,44,52,.74);z-index:1000;display:flex;justify-content:center;align-items:center}
.deleteBox{width:280px;height:180px;background-color:#282c34;font-size:20px;padding:20px;box-shadow:1px 1px 8px 0px #cfd3d6;border-radius:6px}.deleteContainer{position:absolute;top:0;left:0;height:100vh;width:100vw;background-color:rgba(40,44,52,.74);z-index:1000;display:flex;justify-content:center;align-items:center}
.login{display:flex;width:100%;flex-direction:column;align-items:center}.login .login-input-group{position:absolute;display:flex;flex-direction:column;align-items:center;justify-content:center;width:100%;height:100%}.login .login-input-group>.inputField:first-of-type{margin:0 0 1.75em 0}.login .login-input-group>.button{margin:2rem 0 0 0}
.pages{margin:0 20px;display:flex;flex-wrap:wrap;justify-content:space-between}.page{display:flex;flex-direction:column;justify-content:center;align-items:center;font-weight:normal;margin:10px 5px}.pageBox{display:flex;justify-content:center;align-items:center;height:142.5px;width:142.5px;border:1px solid #fff;border-radius:8px}.pageBox span{display:flex;justify-content:center;align-items:center;font-size:22.5px;color:#fff}
