@import '../../styles/variables/colors';

.edit {

  &Box {
    width: 280px;
    height: 380px;
    background-color: #282c34;
    font-size: 20px;
    padding: 20px;
    box-shadow: 1px 1px 8px 0px $grey-1;
    border-radius: 6px;

    form {
      margin: 40px 0 0 0;
    }
  }

  &Container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(40, 44, 52, 0.74);
    z-index: 1000;

    display: flex;
    justify-content: center;
    align-items: center;
  }
}