@import "../../styles/variables/colors";

.login {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;

  .login-input-group {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    > .inputField {
      &:first-of-type {
        margin: 0 0 1.75em 0;
      }
    }

    > .button {
      margin: 2rem 0 0 0;
    }
  }
}
