@import '../../styles/variables/colors';


.button {
  margin: 0.25em 0 0 0;
  border-radius: 0.5rem;
  border: none;
  height: 2rem;
  width: 15rem;
  background-color: $grey-2;
  color: $white;
  font-size: 1.2rem;
  font-weight: 400;

  &[type=reset] {
    background-color: $white;
    color: $black;
  }

  &[type=button] {
    background-color: transparent;
    border: 1px solid $grey-2;
  }
}