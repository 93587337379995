.product {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  align-items: flex-start;

  &:nth-child(even) {
    background-color: rgba(51,56,66,0.7);
  }

  &:nth-child(odd) {
  }

  &Item {
    display: flex;
    flex-direction: column;

    span {
      text-align: start;
      &:first-child {
        font-size: 20px;
      }
      &:nth-child(2) {
        font-size: 16px;
        font-weight: lighter;
      }
    }
  }

  &Price {
    font-weight: bold;
    font-size: 18px;
  }
}
