@import '../../styles/variables/colors';

.selectComponent {
  display: flex;
  justify-content: center;
  position: relative;

  .categoriesList {
    display: none;
    position: absolute;
    top: 2.2rem;
    width: 15rem;
    border-radius: 0.5rem;
    background-color: $white;
    color: $black;
    max-height: 7rem;
    overflow-y: auto;

    &:hover {
      display: block;
    }

    ul {
      padding: 0;
      margin: 0;

      li {
        list-style-type: none;
        padding: 0.5rem 0.75rem;
        font-size: 0.75rem;
        border-bottom: 1px $grey-2 solid;
      }
    }
  }

  .inputField:focus + .categoriesList {
    display: block;
  }
}