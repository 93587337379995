.categoriesPage {

  .logo {
    margin: 25px auto 20px auto;
    width: 80%;
    height: auto;
  }

  .categories {
    margin: 0;
    display: flex;
    overflow-x: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}