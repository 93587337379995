@import '../../styles/variables/colors';

.inputField {
  height: 2rem;
  width: 15rem;
  border-radius: 0.5rem;
  border: 1px solid $grey-1;
  padding: 0 0.5rem 0 0.5rem;

  &::placeholder {
    color: $grey-1;
  }
}