$boxSize: 142.5px;

.pages {
  margin: 0 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: normal;
  margin: 10px 5px;

  &Box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: $boxSize;
    width: $boxSize;

    border: 1px solid white;
    border-radius: 8px;


    span {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 22.5px;
      color: white;
    }
  }
}