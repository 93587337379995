@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;200;400;900&display=swap');
@import 'src/styles/variables/colors';
body {
  margin: 0;
  font-family: 'Roboto', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $black;
  color: floralwhite;
}

::placeholder {
  font-family: 'Roboto', sans-serif;
}

.error {
  color: #ff0000;
  font-size: 0.75rem;
}

.success {
  color: $green-1;
  font-size: 0.75rem;
}

.imageContainer {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  z-index: -1;

  img {
    width: 100%;
    height: 100%;
    filter: brightness(0.4);
    object-fit: cover;
    z-index: 1;
  }
}
